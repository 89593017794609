import { useContext, useEffect, useState } from "react";
import "./sidebar.css";
import companyIcon from "../../images/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import {
  AiFillDashboard,
  AiOutlineSetting,
  AiOutlineClose,
} from "react-icons/ai";
import {
  BsFillPersonCheckFill,
  BsFillJournalBookmarkFill,
} from "react-icons/bs";

import {
  MdNotificationsNone,
  MdPerson,
  MdOutlinePeopleAlt,
} from "react-icons/md";
import { FaUmbrellaBeach, FaBuilding, FaUser } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { BiCreditCardAlt } from "react-icons/bi";
import { FcTodoList } from "react-icons/fc";
import {
  MdOutlineAccountBalance,
  MdPhonelinkSetup,
  MdOutlineEventNote,
  MdPayment,
} from "react-icons/md";
import { BiArea } from "react-icons/bi";
import { HiPencilAlt } from "react-icons/hi";
import { CgNotes } from "react-icons/cg";
import { GiTicket, GiSuitcase } from "react-icons/gi";

import { SiMicrosoftonenote } from "react-icons/si";
import UpperbarContext from "../context/upperbar-context";
import { ShowLogoutPopup } from "../settings/logoutPopup";
import ChangePassword from "../settings/changePassword";
import $ from "jquery";
import UsewindowDimension from "../hooks/UsewindowDimension";

export default function Sidebar({
  sidebarController,
  setSidebarController,
  userDetails,
  logoutAlert,
  setLogoutAlert,
}) {
  const {
    sidebarLogout,
    setSidebarLogout,
    changePassPopup,
    setChangePassPopup,
    padding,
    setPadding,
    sideMenu,
    setSideMenu,
    mode,
    sidePanelBg,
  } = useContext(UpperbarContext);
  const [hide, setHide] = useState(false);

  let navigate = useNavigate();

  const { width } = UsewindowDimension();
  const handleMenuChange = () => {
    if (width < 960) {
      setSideMenu(!sideMenu);
    } else {
      setSideMenu(sideMenu);
    }
  };

  // const handleDrawer = () => {
  //   if (windowWidth < 1100) {
  //     setSidebarController(false);
  //   } else {
  //     setSidebarController(true);
  //   }
  // };

  const showAlert = (e) => {
    setSidebarLogout(true);
  };

  const changePopup = (e) => {
    setChangePassPopup(true);
  };

  const handlePadding = () => {
    document.querySelectorAll(".side__content").forEach((el) => {
      el.classList.add("padding");
    });
  };

  useEffect(() => {
    if (hide) {
      document.querySelectorAll(".react-slidedown").forEach((el) => {
        el.classList.add("d-none");
      });
    } else {
      document.querySelectorAll(".react-slidedown").forEach((el) => {
        el.classList.remove("d-none");
      });
    }
  }, [hide]);

  useEffect(() => {
    if (padding) {
      document.querySelectorAll(".side__content").forEach((el) => {
        el.classList.remove("padding");
      });
    } else {
      document.querySelectorAll(".side__content").forEach((el) => {
        el.classList.add("padding");
      });
    }
  }, [padding]);

  const handleMouseOver = () => {
    setHide(false);
    setPadding(false);
    // if (mode === "light") {
    //   $(".page-title").css({ color: "#656565" });
    //   $(".page-date").css({ color: "#656565" });
    //   $(".title-hr").css({ color: "#656565" });
    // } else {
    //   $(".page-title").css({ color: "#fff" });
    //   $(".page-date").css({ color: "#fff" });
    //   $(".title-hr").css({ color: "#fff" });
    // }
  };
  const handleMouseLeave = () => {
    setHide(!hide);
    setPadding(!padding);
  };

  return (
    <>
      <div
        className={`side__panel ${sideMenu ? "side-menu" : ""}`}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <div className="side-panel-list">
          <div>
            <ProSidebar>
              <Menu>
                <MenuItem
                  icon={<AiFillDashboard size={18} />}
                  className="arrow "
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                >
                  {mode === "en" ? "Dashboard" : "ड्यासबोर्ड"}
                  <NavLink to="/dashboard" />
                </MenuItem>

                <MenuItem
                  icon={<MdPerson size={18} />}
                  className="arrow"
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                >
                  {mode === "en" ? "Profile" : "प्रोफाइल"}
                  <NavLink to="profile" />
                </MenuItem>

                {userDetails.IsManager !== 0 && (
                  <>
                    <SubMenu
                      title={mode === "en" ? "Organizations" : "कार्यालय"}
                      icon={<MdOutlinePeopleAlt size={18} />}
                    >
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-department">
                          {" "}
                          {mode === "en" ? "Department" : "विभाग"}
                        </NavLink>
                      </MenuItem>
                      {/* <MenuItem
                     
                      className="arrow "
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                    >
                      <NavLink to="/admin-subdepartment">
                        {mode === "en" ? "Sub-Department" : "उप विभाग"}
                      </NavLink>
                    </MenuItem> */}
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-designation">
                          {mode === "en" ? "Designation" : "पदनाम"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-shift">
                          {" "}
                          {mode === "en" ? "Shift" : "शिफ्ट"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-shift-roaster">
                          {" "}
                          {mode === "en" ? "Shift Roaster" : "शिफ्ट रोस्टर"}
                        </NavLink>
                      </MenuItem>

                     

                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-branch">
                          {" "}
                          {mode === "en" ? "Branch" : "साखा"}
                        </NavLink>
                      </MenuItem>

                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-fiscal">
                          {" "}
                          {mode === "en" ? "Fiscal" : "वित्तीय"}
                        </NavLink>
                      </MenuItem>

                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-bank">
                          {" "}
                          {mode === "en" ? "Bank" : "बैंक"}
                        </NavLink>
                      </MenuItem>

                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-leave-type">
                          {" "}
                          {mode === "en" ? "Leave Type" : "बिदाको प्रकार"}
                        </NavLink>
                      </MenuItem>

                      {/* <MenuItem
                     
                      className="arrow "
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                    >
                      <NavLink to="/admin-job-information">
                        {mode === "en" ? "Job Information" : "काम जानकारी"}
                      </NavLink>
                    </MenuItem> */}
                    </SubMenu>

                    <SubMenu
                      title={mode === "en" ? "Staff" : "कर्मचारी"}
                      icon={<MdOutlinePeopleAlt size={18} />}
                    >
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-staff">
                          {" "}
                          {mode === "en" ? "Staff" : "कर्मचारी"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-promotion">
                          {" "}
                          {mode === "en" ? "Promotion" : "पदोन्नति"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-resignation">
                          {" "}
                          {mode === "en" ? "Resignation" : "राजीनामा"}
                        </NavLink>
                      </MenuItem>
                      {/* <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="/admin-transfer">
                          {" "}
                          {mode === "en" ? "Transfer" : "स्थानान्तरण"}
                        </NavLink>
                      </MenuItem> */}
                    </SubMenu>

                    <MenuItem
                      icon={<TbReportAnalytics size={18} />}
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                         {mode === "en" ? "Document" : "कागजात"}
                        <NavLink to="/admin-document"/>
                      </MenuItem>
                  </>
                )}


                <SubMenu
                  title={mode === "en" ? "Account" : "खाता"}
                  icon={<MdOutlineAccountBalance size={18} />}
                >
                  <SubMenu
                    title={mode === "en" ? "Setup" : "सेटअप"}
                    icon={<MdPhonelinkSetup size={18} />}
                  >
                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<FaBuilding size={18} />}
                    >
                      <NavLink to="company">
                        {" "}
                        {mode === "en" ? "Company" : "कम्पनी"}
                      </NavLink>
                    </MenuItem>

                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<MdOutlineAccountBalance size={18} />}
                    >
                      <NavLink to="account-group">
                        {mode === "en" ? "Account Group" : "खाता समूह"}
                      </NavLink>
                    </MenuItem>

                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<MdOutlineEventNote size={18} />}
                    >
                      <NavLink to="ledger">
                        {" "}
                        {mode === "en" ? "Ledger" : "लेजर"}
                      </NavLink>
                    </MenuItem>
                    {/* <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<FaUser size={18} />}
                    >
                      <NavLink to="agent">
                        {mode === "en" ? "Agent" : "एजेन्ट"}
                      </NavLink>
                    </MenuItem> */}
                    {/* <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<BiArea size={18} />}
                    >
                      <NavLink to="area">
                        {mode === "en" ? "Area" : "क्षेत्र"}
                      </NavLink>
                    </MenuItem> */}
                  </SubMenu>

                  <SubMenu
                    title={mode === "en" ? "Entry" : "प्रवेश"}
                    icon={<HiPencilAlt size={18} />}
                  >
                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<CgNotes size={18} />}
                    >
                      <NavLink to="voucher">
                        {mode === "en" ? "Voucher" : "भाउचर"}
                      </NavLink>
                    </MenuItem>
                  </SubMenu>
                  <SubMenu
                    title={mode === "en" ? "Report" : "रिपोर्ट"}
                    icon={<TbReportAnalytics size={18} />}
                  >
                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<TbReportAnalytics size={18} />}
                    >
                      <NavLink to="ledger-report">
                        {mode === "en" ? "Ledger" : "लेजर"}
                      </NavLink>
                    </MenuItem>

                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<TbReportAnalytics size={18} />}
                    >
                      <NavLink to="trial-balance">
                        {mode === "en" ? "Trial Balance" : "परीक्षण ब्यालेन्स"}
                      </NavLink>
                    </MenuItem>
                    <MenuItem
                      className="arrow"
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<TbReportAnalytics size={18} />}
                    >
                      <NavLink to="day-book">
                        {" "}
                        {mode === "en" ? "Day Book" : "दिन पुस्तक"}
                      </NavLink>
                    </MenuItem>
                  </SubMenu>
                </SubMenu>

                {userDetails.IsManager !== 0 && (
                  // <SubMenu
                  //   title="Admin"
                  //   icon={<BsFillPersonCheckFill size={18} />}
                  // >
                  //   <SubMenu
                  //     title="Attendance"
                  //     icon={<BsFillPersonCheckFill size={18} />}
                  //   >
                  //     <MenuItem

                  //       className="arrow"
                  //       onMouseOverCapture={handlePadding}
                  //       onClick={handleMenuChange}
                  //     >
                  //       <NavLink to="admin-attendance">Report</NavLink>
                  //     </MenuItem>
                  //     {userDetails.UserID !== "ES15" ? (
                  //       <MenuItem

                  //         className="arrow"
                  //         onMouseOverCapture={handlePadding}
                  //         onClick={handleMenuChange}
                  //       >
                  //         <NavLink to="admin-summary">Summary</NavLink>
                  //       </MenuItem>
                  //     ) : (
                  //       <></>
                  //     )}
                  //   </SubMenu>
                  // </SubMenu>

                  <MenuItem
                    className="arrow "
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<BsFillPersonCheckFill size={18} />}
                  >
                    {mode === "en" ? "Attendance" : "उपस्थित"}
                    <NavLink to="admin-attendance"></NavLink>
                  </MenuItem>
                )}

                <MenuItem
                  icon={<GiSuitcase size={18} />}
                  className="arrow"
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                >
                  {mode === "en" ? "Help Desk" : "सहायता केन्द्र"}
                  <NavLink to="/help-desk" />
                </MenuItem>

                <SubMenu
                  title={mode === "en" ? "Payroll" : "तलब"}
                  icon={<BsFillJournalBookmarkFill size={18} />}
                >
                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="payroll-report">
                      {mode === "en" ? "Payroll Report" : "तलब रिपोर्ट"}
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="addition-deduction">
                      {mode === "en" ? "Addition / Deduction" : "थप / कटौती"}
                    </NavLink>
                  </MenuItem>

                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="provident-fund">
                      {mode === "en" ? "Provident Fund" : "सञ्चय कोष"}
                    </NavLink>
                  </MenuItem>

                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="absent">
                      {mode === "en" ? "Absent" : "अनुपस्थित"}
                    </NavLink>
                  </MenuItem>

                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="salary-facilities">
                      {mode === "en" ? "Salary & Facilities" : "तलब र सुविधा"}
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="generate-salary">
                      {mode === "en"
                        ? "Generate Salary"
                        : "तलब उत्पन्न गर्नुहोस्"}
                    </NavLink>
                  </MenuItem>

                  <MenuItem
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<MdPayment size={18} />}
                  >
                    <NavLink to="personal-slip">
                      {mode === "en" ? "Personal Slip" : "व्यक्तिगत पर्ची"}
                    </NavLink>
                  </MenuItem>
                </SubMenu>

                {/* {userDetails.UserID === "ES15" ? (
                  <MenuItem
                   
                    className="arrow"
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<TbReportAnalytics size={18} />}
                  >
                    Attendance Report
                    <NavLink to="admin-summary"></NavLink>
                  </MenuItem>
                ) : (
                  <></>
                )} */}

                <MenuItem
                  icon={<GiSuitcase size={18} />}
                  className="arrow"
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                >
                  {mode === "en" ? "Job" : "काम"}
                  <NavLink to="/job" />
                </MenuItem>

                {userDetails.IsManager !== 0 && (
                  <MenuItem
                    className="arrow "
                    onMouseOverCapture={handlePadding}
                    onClick={handleMenuChange}
                    icon={<TbReportAnalytics size={18} />}
                  >
                    {mode === "en" ? "Leave Note" : "बिदाको नोट"}
                    <NavLink to="leave-notes"></NavLink>
                  </MenuItem>
                )}

                <MenuItem
                  className="arrow "
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                  icon={<MdNotificationsNone size={18} />}
                >
                  {mode === "en" ? "Notification" : "सूचना"}
                  <NavLink to="notification"></NavLink>
                </MenuItem>

                {/* <SubMenu title="Report" icon={<TbReportAnalytics size={18} />}> */}
                {userDetails.IsManager === 0 && (
                  <>
                    <SubMenu
                      title={mode === "en" ? "Attendance" : "उपस्थित"}
                      icon={<BsFillPersonCheckFill size={18} />}
                    >
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="report-attendance">
                          {" "}
                          {mode === "en" ? "Report" : "रिपोर्ट"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        className="arrow "
                        onMouseOverCapture={handlePadding}
                        onClick={handleMenuChange}
                      >
                        <NavLink to="attendance-summary">
                          {" "}
                          {mode === "en" ? "Summary" : "सारांश"}
                        </NavLink>
                      </MenuItem>
                    </SubMenu>
                    <MenuItem
                      className="arrow "
                      onMouseOverCapture={handlePadding}
                      onClick={handleMenuChange}
                      icon={<SiMicrosoftonenote size={18} />}
                    >
                      {mode === "en" ? "Leave Note" : "बिदाको नोट"}
                      <NavLink to="leave-note"></NavLink>
                    </MenuItem>
                  </>
                )}

                {/* </SubMenu> */}

                <MenuItem
                  className="arrow "
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                  icon={<FaUmbrellaBeach size={18} />}
                >
                  <NavLink to="/admin-holiday">
                    {" "}
                    {mode === "en" ? "Event" : "घटना"}
                  </NavLink>
                </MenuItem>

                <MenuItem
                  className="arrow "
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                  icon={<TbReportAnalytics size={18} />}
                >
                  <NavLink to="/todo">
                    {" "}
                    {mode === "en" ? "Todo" : "काम"}
                  </NavLink>
                </MenuItem>

                {/* <MenuItem
                 
                  className="arrow "
                  onMouseOverCapture={handlePadding}
                  onClick={handleMenuChange}
                  icon={<FaUmbrellaBeach size={18} />}
                >
                  Holiday
                  <NavLink to="holiday"></NavLink>
                </MenuItem> */}

                <SubMenu
                  title={mode === "en" ? "Setting" : "सेटिङ"}
                  icon={<AiOutlineSetting size={18} />}
                >
                  <MenuItem className="arrow ">
                    <div onClick={changePopup}>
                      {" "}
                      {mode === "en" ? "Change Password" : "पासवर्ड परिवर्तन"}
                    </div>
                  </MenuItem>
                  <MenuItem className="arrow ">
                    <div onClick={showAlert}>
                      {" "}
                      {mode === "en" ? "Logout" : "बाहिर निस्कनु"}
                    </div>
                  </MenuItem>
                </SubMenu>
              </Menu>
            </ProSidebar>
          </div>
        </div>
      </div>
      <ShowLogoutPopup />
      <ChangePassword />
    </>
  );
}
